@import "normalize.css";
@font-face {
  font-family: "Josefin Sans";
  src: url("./fonts/josefin-sans/JosefinSans-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Josefin Sans";
  src: url("./fonts/josefin-sans/JosefinSans-Light.ttf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Josefin Sans";
  src: url("./fonts/josefin-sans/JosefinSans-Thin.ttf");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Josefin Sans";
  src: url("./fonts/josefin-sans/JosefinSans-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Josefin Sans";
  src: url("./fonts/josefin-sans/JosefinSans-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}
* {
  outline: none !important;
}
html {
  font-size: 62.5%;
  font-family: Muli, Roboto, Helvetica Neue, Arial, sans-serif;
  background-color: #262933;
}
body {
  font-size: 14px;
  line-height: 1.4;
}
html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
h1,
.h1 {
  font-size: 24px;
}
h2,
.h2 {
  font-size: 20px;
}
h3,
.h3 {
  font-size: 16px;
}
h4,
.h4 {
  font-size: 15px;
}
h5,
.h5 {
  font-size: 13px;
}
h6,
.h6 {
  font-size: 12px;
}
.ps > .ps__rail-y,
.ps > .ps__rail-x {
  z-index: 99;
}
a[role="button"] {
  text-decoration: none;
}
@import "normalize.css";

@font-face {
  font-family: "Josefin Sans";
  src: url("./fonts/josefin-sans/JosefinSans-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Josefin Sans";
  src: url("./fonts/josefin-sans/JosefinSans-Light.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Josefin Sans";
  src: url("./fonts/josefin-sans/JosefinSans-Thin.ttf");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Josefin Sans";
  src: url("./fonts/josefin-sans/JosefinSans-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Josefin Sans";
  src: url("./fonts/josefin-sans/JosefinSans-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

* {
  outline: none !important;
}

html {
  font-size: 62.5%;
  font-family: Muli, Roboto, Helvetica Neue, Arial, sans-serif;
  background-color: #262933;
}

body {
  font-size: 14px;
  line-height: 1.4;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

h1,
.h1 {
  font-size: 24px;
}

h2,
.h2 {
  font-size: 20px;
}

h3,
.h3 {
  font-size: 16px;
}

h4,
.h4 {
  font-size: 15px;
}

h5,
.h5 {
  font-size: 13px;
}

h6,
.h6 {
  font-size: 12px;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
  z-index: 99;
}

a[role="button"] {
  text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: transparent;
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar:hover {
    width: 12px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0.06);
  }

  ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb:active {
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
    border-radius: 20px;
  }
}

form label {
  z-index: 99;
}

@import "print.css";

@import "tables.css";

@import "react-table.css";

@import "prism.css";

@import "fuse-helpers.css";

@media only screen and (min-width: 992px) {
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar:hover {
    width: 12px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0.06);
  }
  ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:active {
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
    border-radius: 20px;
  }
}
form label {
  z-index: 99;
}
@import "print.css";
@import "tables.css";
@import "react-table.css";
@import "prism.css";
@import "fuse-helpers.css";
